import React from 'react';
import numeral from 'numeral';

export const getActualGeoNameField = value =>
  value.place_name ?? value.text ?? value.name ?? value.label ?? '';

export const getZipcodesLabel = zips => {
  let label = zips.slice(0, 3).join(', ');
  if (zips.length > 3) {
    label += ` + ${zips.length - 3} more...`;
  }
  label += ' (Zip Codes)';
  return label;
};

export const getUnlockedContent = (option, unlockedContent) => {
  switch (option.display_name) {
    case 'Max Impressions':
    case 'Auto Bid':
    case 'Max ROAS':
      return unlockedContent.maxCpm(false);
    case 'Manual Bid':
      return unlockedContent.maxCpm(true);
    case 'Max Outcomes':
      return (
        <>
          {unlockedContent.eventPicker()}
          {unlockedContent.maxCpm(false)}
        </>
      )
    default:
      return null;
  }
};

export const getLockedStatus = (option, bidStrategyEventSet) => {
  switch (option.display_name) {
    case 'Max Outcomes':
      return !bidStrategyEventSet || bidStrategyEventSet.length === 0;
    default:
      return false;
  }
};

export const getLockedInfo = (option, lockedInfo) => {
  switch (option.display_name) {
    case 'Max Outcomes':
      return lockedInfo.trackingLockedInfo;
    default:
      return null;
  }
};
export const getTargetingValue = (key, targeting = []) =>
  targeting.find(t => t.key === key);

const getAdjustedBidRange = range => range.map(n => n * 1.18 + 0.4);

export const getRecommendedBidRange = ({ bundles, inventoryOption, peacock }) => {
  switch (inventoryOption) {
    // TODO: When DS returns a suggested bid range in the future, we should
    // display it in the 'performanceOptimized' case. In the meantime,
    // 'performanceOptimized' gets the default behavior that also applies to
    // 'premium-reach'.

    // case 'performanceOptimized': {
    //   return;
    // }
    case 'bundles': {
      return bundles.map(({ adjusted_cpm }) => adjusted_cpm)
      .filter(cpm => !!cpm)
      .sort((a, b) => a - b);
    }
    case 'premium-reach':
    default: {
      const range = peacock ? [17, 21] : [10, 20];
      return getAdjustedBidRange(range);
    }
  }
};

export const getRangeText = (range) => {
  const min = range[0];
  const max = range[range.length - 1];

  if (min === max) {
    return `${numeral(Math.ceil(min)).format('$0,0')}`;
  }

  return `${numeral(min).format('$0,0')} - ${
    numeral(Math.ceil(max)).format('0,0')
  }`;
};


