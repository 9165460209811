export const FormFields = {
  AGENCY_ID: 'agencyId',
  BILLING_ID: 'billingId',
  DAILY_SPEND: 'dailySpend',
  INVOICE_APPROVED: 'invoiceApproved',
};

export const DefaultValues = {
  [FormFields.AGENCY_ID]: '',
  [FormFields.BILLING_ID]: '',
  [FormFields.DAILY_SPEND]: '',
  [FormFields.INVOICE_APPROVED]: false,
};
