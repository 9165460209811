export const enhanceFieldMappings = (fieldMappings, formFields, apiFields) => Object.keys(formFields)
  .reduce((enhancedMappings, formKey) => {
    if (!enhancedMappings[formFields[formKey]]) {
      enhancedMappings[formFields[formKey]] = apiFields[formKey] || formKey;
    }

    return enhancedMappings;
  }, { ...fieldMappings });

export const mapFields = (data, fieldMappings, defaults = {}) => {
  return Object.fromEntries(
    Object.entries(fieldMappings).map(([formKey, apiKeys]) =>
      [formKey, data[apiKeys] ?? defaults[formKey]]
    )
  );
};

export const reverseMapping = (fieldMappings) => {
  return Object.fromEntries(
    Object.entries(fieldMappings).map(([formKey, apiKeys]) =>
      [apiKeys, formKey]
    )
  );
};

export const mapFieldsBack = (data, fieldMappings, defaults = {}) => {
  const reversedMappings = reverseMapping(fieldMappings);

  return Object.fromEntries(
    Object.entries(reversedMappings).map(([apiKey, formKey]) =>
      [apiKey, data[formKey] ?? defaults[formKey]]
    )
  );
};
