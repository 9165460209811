import { enhanceFieldMappings, reverseMapping, mapFields } from '@utils';
import {
  AdminAPIFields as APIFields,
  AdminFieldMappings as FieldMappings,
} from '../constants';
import { FormFields } from '../components/forms/AdminForm/constants';

export const AdminFieldMappings = enhanceFieldMappings(
  FieldMappings,
  FormFields,
  APIFields,
);

export const AdminReverseFieldMappings = reverseMapping(AdminFieldMappings);

export const mapAdminFields = data => mapFields(data, AdminFieldMappings);
