import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useState, useContext } from 'react';
import { useUser } from '@components/hooks';
import AdvertiserContext from '@components/AdvertiserContext';
import { Themes } from '@constants/themes';
import {
  customInventoryTabValues,
  defaultCustomInventoryTabs,
  inventoryCategoryName,
  padmanCustomInventoryTabs,
} from '../../constants';
import { ReactiveIconStyled } from '../../styles';
import { useAdvancedGeneralTable } from './useAdvancedGeneralTable';
import { useTabs } from './useTabs';
import { getNonEmptyArrayValidation } from './validation';
import { useAdvancedCustomInventoryData } from './useAdvancedCustomInventoryData';
import { first, slice } from 'lodash';

const rightSideGroupField = 'type';
const dataFieldName = 'display_name';

export const useAdvancedCustomInventoryTable = data => {
  const { items, selected } = data;
  const { user } = useUser();
  const { theme } = useContext(AdvertiserContext);
  const customInventoryTabs =
    theme === Themes.NBCU
      ? padmanCustomInventoryTabs
      : defaultCustomInventoryTabs;
  const [groupByFieldName, setGroupByFieldName] = useState();

  const filteredTabs = useMemo(() => {
    const isInternal = user.is_tvsci_employee;
    return customInventoryTabs.filter(tab => isInternal || !tab.internalOnly);
  }, [user]);

  const { tab, tabs, setTab } = useTabs(filteredTabs);

  const filteredData = useMemo(() => {
    switch (tab) {
      case customInventoryTabValues.dealId:
      case customInventoryTabValues.appListId:
      case customInventoryTabValues.appName:
        // TODO: Filter items for these tabs once API is returning them
        return [];
      case customInventoryTabValues.inventory:
        setGroupByFieldName('type');
        return items;
      case customInventoryTabValues.channels:
      default:
        setGroupByFieldName('');
        return items.filter(i => {
          return i.type === 'Network';
        });
    }
  }, [items, tab]);

  const legend = useMemo(() => {
    const legendList = [];

    if (filteredData.some(i => i.featured)) {
      legendList.push({
        type: 'color',
        value: 'chart.6',
        label: `Featured ${tab}`,
      });
    }

    if (filteredData.some(i => i.recommended)) {
      legendList.push({
        type: 'node',
        label: `Recommended ${tab}`,
        value: <ReactiveIconStyled name="reactive" />,
      });
    }

    return legendList;
  }, [filteredData, tab]);

  const tableProps = useAdvancedGeneralTable({
    data: filteredData,
    selected,
    dataFieldName,
    groupByFieldName,
    groupSelectedByFieldName: rightSideGroupField,
    resolver: () => yupResolver(getNonEmptyArrayValidation()),
    featuredFieldName: 'Featured Genres',
  });

  const handleSetSelected = v => {
    const lastAdded = first(v);
    if (lastAdded) {
      const sameType = v.find(
        ({ type, id }) => lastAdded.type === type && lastAdded.id !== id,
      );

      tableProps.setSelected([
        {
          ...lastAdded,
          included:
            lastAdded.type ===
            inventoryCategoryName[customInventoryTabValues.dealId]
              ? false
              : !sameType || sameType.included,
        },
        ...slice(v, 1),
      ]);

      return;
    }

    tableProps.setSelected(v);
  };

  // For the "App Name", "App List ID", and "Deal ID" tabs we have to filter remotely
  const inventoryData = useAdvancedCustomInventoryData({
    tab,
    selected: tableProps.selected,
    setSelected: handleSetSelected,
    dataFieldName,
    filter: tableProps.filter,
  });

  const handleCheckGroup = ({ groupName, value }) => {
    tableProps.setSelected(
      tableProps.selected.map(v => ({
        ...v,
        included: v[rightSideGroupField] === groupName ? value : v.included,
      })),
    );
  };

  const handleIncludeAll = () => {
    tableProps.setSelected(
      tableProps.selected.map(s => ({
        ...s,
        included:
          s.type === inventoryCategoryName[customInventoryTabValues.dealId]
            ? false
            : true,
      })),
    );
  };

  const handleExcludeAll = () => {
    tableProps.setSelected(
      tableProps.selected.map(s => ({ ...s, included: false })),
    );
  };

  return {
    ...tableProps,
    ...inventoryData,
    legend,
    tab,
    tabs,
    setTab,
    setSelected: handleSetSelected,
    checkGroup: handleCheckGroup,
    includeAll: handleIncludeAll,
    exludeAll: handleExcludeAll,
  };
};
