import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAuth, useQuery } from '../components/hooks';
import apiInstance from '../connection/v2apiClient';
import { Themes } from '../constants';
import { getPrimaryOrgId } from '../helpers';

const CurrentSessionContext = React.createContext(null);

CurrentSessionContext.displayName = 'CurrentSessionContext';

// NOTE: This provider should not fetch data, as most fetches depend on the current advertiser for context
// NOTE: the AdvertisersContextProvider should use this hook to update the currentAdvertiser
// TODO: Replace adContext with this
export const CurrentSessionContextProvider = ({ children }) => {
  const { authState } = useAuth();
  const [currentAdvertiser, setCurrentAdvertiser] = useState({
    id: null,
    name: '',
    theme: Themes.NBCU,
    cost_model: null,
  });

  // TODO: ask product for param names
  const urlQuery = useQuery();
  const sessionAdAccount = urlQuery.get('adAccount');
  // @ag-tvs Could we get rid of the org header?
  const sessionOrg = urlQuery.get('org');

  const updateCurrentAdvertiser = data => {
    setCurrentAdvertiser(currentData => ({
      ...currentData,
      ...data,
    }));
  };

  useEffect(() => {
    if (authState.accessToken) {
      apiInstance.defaults.headers.common['Authorization'] =
        `Bearer ${authState.accessToken}`;
    }
  }, [authState?.accessToken]);

  const getHeaders = () => {
    const headers = { ...apiInstance.defaults.headers };
    if (sessionAdAccount) {
      headers['X-TVS-AdvertiserContext'] = sessionAdAccount;
      headers['X-TVS-OrganizationContext'] = getPrimaryOrgId(sessionOrg);
    } else if (currentAdvertiser?.id !== 0) {
      headers['X-TVS-AdvertiserContext'] = currentAdvertiser.id;
      headers['X-TVS-OrganizationContext'] = getPrimaryOrgId(
        currentAdvertiser.primary_org,
      );
    }
    return headers;
  };

  const get = (url, params) => {
    return apiInstance.get(url, { params, headers: getHeaders() });
  };

  const getV1 = (url, params) => {
    return apiInstance.get(url, {
      params,
      baseURL: `${process.env.API_URL}/v1/`,
      headers: getHeaders(),
    });
  };

  const putV1 = (url, data) => {
    return apiInstance.put(url, data, {
      baseURL: `${process.env.API_URL}/v1/`,
      headers: getHeaders(),
    });
  };

  const patchV1 = (url, data) => {
    return apiInstance.patch(url, data, {
      baseURL: `${process.env.API_URL}/v1/`,
      headers: getHeaders(),
    });
  };

  const post = (url, data) => {
    return apiInstance.post(url, data, { headers: getHeaders() });
  };

  const postV1 = (url, data, { headers, ...rest } = {}) => {
    return apiInstance.post(url, data, {
      baseURL: `${process.env.API_URL}/v1/`,
      headers: { ...getHeaders(), ...headers },
      ...rest,
    });
  };

  const patch = (url, data) => {
    return apiInstance.patch(url, data, { headers: getHeaders() });
  };

  const del = (url, params) => {
    return apiInstance.delete(url, { params, headers: getHeaders() });
  };

  const delV1 = (url, params) => {
    return apiInstance.delete(url, {
      params,
      baseURL: `${process.env.API_URL}/v1/`,
      headers: getHeaders(),
    });
  }

  return (
    <CurrentSessionContext.Provider
      value={{
        apiIsReady: Boolean(
          apiInstance.defaults.headers.common?.Authorization,
        ),
        get,
        getV1,
        post,
        postV1,
        putV1,
        patch,
        patchV1,
        del,
        delV1,
        currentAdvertiser,
        setCurrentAdvertiser,
        updateCurrentAdvertiser,
      }}
    >
      {children}
    </CurrentSessionContext.Provider>
  );
};

CurrentSessionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CurrentSessionContext;
