import PropTypes from 'prop-types';
import React from 'react';
import { FirstCellVirtualizedStyled } from '../styles';
import { optionalOptionType } from '../types';

import { AutoSizer, Table } from 'react-virtualized';
import { useTableVirtualizedData } from '../hooks/useTableVirtualizedData';
import { useVirtualizedRenderer } from './hooks/useVirtualizedRenderer';

export const TableLeftVirtualized = ({
  data = [],
  dataName,
  onChange,
  renderCell,
  renderGroup,
  PlaceholderGroup,
  isTargeted,
  PlaceholderCell,
}) => {
  const { list } = useTableVirtualizedData({
    data,
  });

  const rowRenderer = useVirtualizedRenderer({
    onChange,
    renderCell,
    renderGroup,
    PlaceholderCell,
    PlaceholderGroup,
    isTargeted,
  });

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          width={width}
          height={height}
          headerHeight={74}
          rowHeight={index => {
            const item = list[index];
            if (!item || item.type !== 'table-group-row') return 74;

            return 31;
          }}
          headerRowRenderer={() => (
            <FirstCellVirtualizedStyled>
              {dataName}
            </FirstCellVirtualizedStyled>
          )}
          rowCount={list.length}
          rowGetter={({ index }) => list[index]}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
};

TableLeftVirtualized.propTypes = {
  data: PropTypes.arrayOf(optionalOptionType),
  dataName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isTargeted: PropTypes.bool,
  renderCell: PropTypes.func.isRequired,
  renderGroup: PropTypes.func,
  PlaceholderCell: PropTypes.func,
  PlaceholderGroup: PropTypes.func,
};
