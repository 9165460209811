import { fromHighlightedContent } from '@v2/components/campaign/Advanced/utils';
import {
  chain,
  escapeRegExp,
  findIndex,
  isNull,
  last,
  partition,
  slice,
} from 'lodash';
import { groupDataByFields, toHighlightedContent } from '../../../Advanced';

export const transformToContext = (data, filter, by) => {
  const result = [...data];
  if (filter) {
    const reg = new RegExp(escapeRegExp(filter), 'i');

    return result
      .filter(v => reg.test(v[by]) || reg.test(v.id))
      .map(v => ({
        ...v,
        [by]: toHighlightedContent(v[by], filter),
      }));
  }

  return result;
};

export const transformFromContext = (data, by) => {
  if (Array.isArray(data)) {
    return data.map(v => ({
      ...v,
      [by]: fromHighlightedContent(v[by]),
    }));
  }

  return data;
};

export const createGroupList = (list, groupName) =>
  list.length
    ? [
        {
          groupName,
          data: list,
        },
      ]
    : [];

export const transformToSimpleArrayGroups = (data, field) => {
  if (!data.length) {
    return [];
  }

  const firstNull = findIndex(data, el => isNull(el));
  const nonNull = slice(data, 0, firstNull === -1 ? undefined : firstNull);
  const nulls = slice(data, firstNull === -1 ? undefined : firstNull);

  const preparedGroups = chain(nonNull)
    .groupBy(field)
    .entries()
    .map(([groupName, data]) => ({ groupName, data }))
    .value();

  const lastGroup = last(preparedGroups);

  if (!lastGroup) {
    return nulls;
  }

  lastGroup.data = [...lastGroup.data, ...nulls];

  return preparedGroups;
};

export const transformToGroups = ({
  data,
  generalGroupField,
  featuredFieldName,
  privateFieldName,
  nonTypedFieldName,
}) => {
  const [pBundles, fBundles, nonBundles, list] = chain(data)
    .thru(list => partition(list, ({ private: pBundle }) => pBundle))
    .thru(([pBundles, list]) => [
      pBundles,
      ...partition(list, ({ featured }) => featured),
    ])
    .thru(([pBundles, fBundles, list]) => [
      pBundles,
      fBundles,
      ...partition(list, ({ type }) => !type || type === 'None'),
    ])
    .value();

  return [
    ...createGroupList(fBundles, featuredFieldName),
    ...createGroupList(pBundles, privateFieldName),
    ...createGroupList(nonBundles, nonTypedFieldName),
    ...groupDataByFields(list, generalGroupField),
  ];
};
