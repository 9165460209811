import { RemoveCircleOutline } from '@mui/icons-material';
import { styled } from '@mui/material';

export const StyledRemoveIcon = styled(RemoveCircleOutline)(({ theme }) => ({
  color: theme.palette.red[1],
  opacity: 0,
  transition: 'opacity 0.3s',
  marginLeft: theme.spacing(0.5),
  cursor: 'pointer',
  pointerEvents: 'none',
  '&:hover': {
    opacity: 1,
  },
  '.hover-row:hover &': {
    opacity: 1,
    pointerEvents: 'auto',
  },
  '.remove-all-hover &': {
    opacity: 1,
    pointerEvents: 'auto',
  },
}));
