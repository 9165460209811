const CAN_ACCESS_APPLICATION = 'CAN_ACCESS_APPLICATION';
const CAN_LIST_CAMPAIGN = 'CAN_LIST_CAMPAIGN';
const CAN_CREATE_CAMPAIGN = 'CAN_CREATE_CAMPAIGN';
const CAN_UPDATE_CAMPAIGN = 'CAN_UPDATE_CAMPAIGN';
const CAN_READ_REPORTING = 'CAN_READ_REPORTING';
const CAN_VIEW_BUSINESS_MANAGER = 'CAN_VIEW_BUSINESS_MANAGER';
const CAN_VIEW_NBCU_ORGANIZATION = 'CAN_VIEW_NBCU_ORGANIZATION';
const CAN_UPDATE_ADVERTISER = 'CAN_UPDATE_ADVERTISER';
const CAN_CREATE_USER = 'CAN_CREATE_USER';
const USER_SHOULD_SETUP_ACCOUNT = 'USER_SHOULD_SETUP_ACCOUNT';
const CAN_SET_SMALL_FLAT_CPM = 'CAN_CREATE_TENANT';
const CAN_VIEW_QC = 'CAN_VIEW_QC';

export const Scopes = {
  CAN_ACCESS_APPLICATION,
  CAN_LIST_CAMPAIGN,
  CAN_CREATE_CAMPAIGN,
  CAN_UPDATE_CAMPAIGN,
  CAN_READ_REPORTING,
  CAN_VIEW_BUSINESS_MANAGER,
  CAN_VIEW_NBCU_ORGANIZATION,
  CAN_UPDATE_ADVERTISER,
  CAN_CREATE_USER,
  USER_SHOULD_SETUP_ACCOUNT,
  CAN_SET_SMALL_FLAT_CPM,
  CAN_VIEW_QC,
};

export const DefaultPermissions = {
  [CAN_ACCESS_APPLICATION]: true,
  [CAN_LIST_CAMPAIGN]: false,
  [CAN_CREATE_CAMPAIGN]: false,
  [CAN_UPDATE_CAMPAIGN]: false,
  [CAN_READ_REPORTING]: false,
  [CAN_VIEW_BUSINESS_MANAGER]: false,
  [CAN_VIEW_NBCU_ORGANIZATION]: false,
  [CAN_UPDATE_ADVERTISER]: false,
  [CAN_CREATE_USER]: false,
  [USER_SHOULD_SETUP_ACCOUNT]: false,
  [CAN_SET_SMALL_FLAT_CPM]: false,
  [CAN_VIEW_QC]: false,
};
