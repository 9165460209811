import React from 'react';
import { TableLeftRowVirtualized } from '../TableLeftRowVirtualized';

export const useVirtualizedRenderer =
  ({
    onChange,
    renderCell,
    renderGroup,
    PlaceholderCell,
    PlaceholderGroup,
    isTargeted,
  }) =>
  /* eslint-disable react/prop-types */
  /* eslint-disable react/display-name */
  ({ key, index, style, rowData }) => {
    return rowData.type === 'table-group-row' ? (
      renderGroup ? (
        renderGroup({
          key,
          data: rowData,
          style,
          type: 'left',
        })
      ) : (
        <PlaceholderGroup
          key={key}
          style={style}
          type={'left'}
          data={rowData}
        />
      )
    ) : (
      <TableLeftRowVirtualized
        key={key}
        index={index}
        data={rowData}
        style={style}
        isTargeted={isTargeted}
        renderCell={renderCell}
        PlaceholderCell={PlaceholderCell}
        onChange={onChange}
      />
    );
  };
