import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { PropTypes } from 'prop-types';
import { AdvancedElementRowStyled, FirstCellStyled } from '../styles';
import { optionalOptionType, tableSettings } from '../types';
import { IncludeExcludeSwitch } from '@v2/components/ui/Switch';
import { StyledRemoveIcon } from './styles';
import clsx from 'clsx';

export const TableRightRow = ({
  data,
  index,
  isTargeted,
  renderCell,
  PlaceholderCell,
  isRemoveAllHovered,
  onChange,
  settings,
  onUpdate,
  ...props
}) => {
  return (
    <AdvancedElementRowStyled
      {...props}
      rowType="remove"
      className={clsx(
        props.className,
        `${data ? 'hover-row' : ''} ${isRemoveAllHovered ? 'remove-all-hover' : ''}`,
      )}
      targeted={data && isTargeted}
      onClick={() => {
        if (data) {
          onChange(data);
        }
      }}
      highlighted={index % 2}
      key={data?.id ?? `placeholder-${index}`}
    >
      <FirstCellStyled>
        {data ? renderCell(data) : <PlaceholderCell data={data} />}
      </FirstCellStyled>

      {settings.includable && settings.showSeparateSwitch && (
        <TableCell
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {!!data && (
            <IncludeExcludeSwitch
              defaultChecked
              disabled={!settings.separateInclExcl}
              onChange={e => {
                onUpdate({ ...data, included: e.target.checked });
              }}
              checked={data.included}
            />
          )}
        </TableCell>
      )}
      <TableCell
        sx={{
          overflow: 'hidden',
        }}
      >
        <StyledRemoveIcon
          className="hover-icon"
          color="primary"
          sx={{
            maxWidth: '100%',
            display: !data || !isTargeted ? 'none' : 'block',
            opacity: '33%',
          }}
        />
      </TableCell>
    </AdvancedElementRowStyled>
  );
};

TableRightRow.propTypes = {
  ...TableRow.propTypes,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  data: optionalOptionType,
  isTargeted: PropTypes.bool,
  renderCell: PropTypes.func.isRequired,
  PlaceholderCell: PropTypes.func,
  settings: tableSettings,
  onUpdate: PropTypes.func.isRequired,
  isRemoveAllHovered: PropTypes.bool,
};
