import {
  mapFields,
  enhanceFieldMappings,
  reverseMapping,
  parseAddress,
} from '@utils';
import {
  GeneralInfoAPIFields as APIFields,
  GeneralInfoFieldMappings as FieldMappings
} from '../constants';
import { FormFields } from '../components/forms/GeneralInfoForm/constants';

export const GeneralInfoFieldMappings = enhanceFieldMappings(
  FieldMappings,
  FormFields,
  APIFields,
);

export const GeneralInfoReverseFieldMappings = reverseMapping(GeneralInfoFieldMappings);

export const mapGeneralInfoFields = (data) => {
  const formFields = mapFields(data, GeneralInfoFieldMappings);

  if (data[APIFields.BUSINESS_LEGAL_NAME]) {
    formFields[APIFields.NAME] = data[APIFields.BUSINESS_LEGAL_NAME];
  }

  if (data[APIFields.BUSINESS_PHYSICAL_ADDRESS] && (
    !data[APIFields.STREET_ADDRESS] &&
    !data[APIFields.CITY] &&
    !data[APIFields.STATE] &&
    !data[APIFields.POSTAL_CODE]
  )) {
    const Address = parseAddress(data[APIFields.BUSINESS_PHYSICAL_ADDRESS]);

    formFields[APIFields.CITY] = Address[APIFields.CITY];
    formFields[APIFields.STATE] = Address[APIFields.STATE];
    formFields[APIFields.STREET_ADDRESS] = Address[APIFields.STREET_ADDRESS];
    formFields[APIFields.STREET_ADDRESS_2] = Address[APIFields.STREET_ADDRESS_2];
  }

  return formFields;
};
