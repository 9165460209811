import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { TableGroupCellStyled } from './styles';

export const PlaceholderGroup = ({ data, type }) => {
  return (
    <TableGroupCellStyled colSpan={type === 'left' ? 2 : 3}>
      <Typography variant="caption" color="grey.1">
        {data.groupName}
      </Typography>
    </TableGroupCellStyled>
  );
};

PlaceholderGroup.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
};
