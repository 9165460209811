import { FormFields } from '../components/forms/GeneralInfoForm/constants';

export const GeneralInfoAPIFields = {
  NAME: 'name',
  BUSINESS_LEGAL_NAME: 'business_legal_name',
  STREET_ADDRESS: 'street_address',
  STREET_ADDRESS_2: 'street_address_2',
  CITY: 'city',
  STATE: 'state',
  POSTAL_CODE: 'postal_code',
  BUSINESS_PHONE: 'business_phone',
  DOING_BUSINESS_AS: 'doing_business_as',
  BUSINESS_PHYSICAL_ADDRESS: 'business_physical_address',
};

export const GeneralInfoFieldMappings = {
  [FormFields.ADDRESS_1]: GeneralInfoAPIFields.STREET_ADDRESS,
  [FormFields.ADDRESS_2]: GeneralInfoAPIFields.STREET_ADDRESS_2,
  [FormFields.ZIP]: GeneralInfoAPIFields.POSTAL_CODE,
  [FormFields.PHONE]: GeneralInfoAPIFields.BUSINESS_PHONE,
  [FormFields.DBA]: GeneralInfoAPIFields.DOING_BUSINESS_AS,
};
