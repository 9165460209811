import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { PropTypes } from 'prop-types';
import React, { Fragment } from 'react';
import {
  AdvancedTableStyled,
  FirstCellStyled,
  LastCellStyled,
  TableContainerStyled,
} from '../styles';
import { optionalOptionType, tableSettings } from '../types';
import { TableRightRow } from './TableRightRow';

export const TableRight = ({
  data = [],
  onChange,
  onUpdate,
  renderCell,
  renderGroup,
  isTargeted,
  PlaceholderCell,
  PlaceholderGroup,
  settings,
  isRemoveAllHovered,
}) => {
  return (
    <TableContainerStyled>
      <AdvancedTableStyled>
        <TableHead>
          <TableRow>
            <FirstCellStyled>Selected</FirstCellStyled>
            {settings.includable && <TableCell>Incl/Excl</TableCell>}
            {!settings.includable ||
            (settings.includable && settings.showSeparateSwitch) ? (
              <LastCellStyled />
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody data-testid="advanced-right-table-body">
          {data?.[0]?.groupName
            ? data.map((v, i) => (
                <Fragment key={`${v.groupName}-${i}`}>
                  <TableRow>
                    {renderGroup ? (
                      renderGroup({
                        data: v,
                        type: 'right',
                      })
                    ) : (
                      <PlaceholderGroup type={'right'} data={v} />
                    )}
                  </TableRow>
                  {v.data.map((d, j) => (
                    <TableRightRow
                      key={data.id ?? `placeholder-${v.groupName}-${i}-${j}`}
                      data={d}
                      index={j}
                      isTargeted={v && isTargeted}
                      onChange={onChange}
                      onUpdate={onUpdate}
                      PlaceholderCell={PlaceholderCell}
                      renderCell={renderCell}
                      settings={settings}
                      isRemoveAllHovered={isRemoveAllHovered}
                    />
                  ))}
                </Fragment>
              ))
            : data.map((v, i) => (
                <TableRightRow
                  key={v?.id ?? `placeholder-${i}`}
                  data={v}
                  index={i}
                  isRemoveAllHovered={isRemoveAllHovered}
                  onChange={onChange}
                  onUpdate={onUpdate}
                  renderCell={renderCell}
                  isTargeted={isTargeted}
                  PlaceholderCell={PlaceholderCell}
                  settings={settings}
                />
              ))}
        </TableBody>
      </AdvancedTableStyled>
    </TableContainerStyled>
  );
};

TableRight.propTypes = {
  data: PropTypes.arrayOf(optionalOptionType),
  onChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isTargeted: PropTypes.bool,
  renderCell: PropTypes.func.isRequired,
  renderGroup: PropTypes.func,
  PlaceholderCell: PropTypes.func,
  PlaceholderGroup: PropTypes.func,
  settings: tableSettings,
  isRemoveAllHovered: PropTypes.bool.isRequired,
};
