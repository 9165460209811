import React, {
  useCallback,
  useContext,
  useState,
} from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { Box, Paper, Typography, Tabs, Tab } from '@mui/material';
import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';
import AdAccounts from '../AdAccounts';
import BillingAccounts from '../BillingAccounts';
import ManageOrganization from '../ManageOrganization';
import UserAccounts from '../UserAccounts';
import LoadingSpinner from '../ui/LoadingSpinner';
import { useOrg } from '../hooks';

const PREFIX = 'BusinessManagerPage';

const classes = {
  root: `${PREFIX}-root`,
  headerWrap: `${PREFIX}-headerWrap`,
  header: `${PREFIX}-header`,
  company: `${PREFIX}-company`,
  tab: `${PREFIX}-tab`,
  tabs: `${PREFIX}-tabs`
};

const StyledAppHeader = styled(AppHeader)(({
  theme: { palette, spacing }
}) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    marginTop: 60,
    marginBottom: 60,
  },

  [`& .${classes.headerWrap}`]: {
    position: 'absolute',
    top: spacing(7),
    left: spacing(4),
    width: 120,
    textAlign: 'right',
  },

  [`& .${classes.header}`]: {
    fontSize: '0.75rem',
  },

  [`& .${classes.company}`]: {
    color: '#1dafff',
    fontSize: '0.75rem',
    wordWrap: 'break-word',
  },

  [`& .${classes.tab}`]: {
    textAlign: 'right',

    '&.Mui-selected': {
      fontSize: '1rem',
    },
  },

  [`& .${classes.tabs}`]: {
    borderRight: `1px solid ${palette.divider}`,
    paddingTop: spacing(18),
    width: 180,
  }
}));

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: '100%', minHeight: 880, flex: 1 }}
      {...other}
    >
      {value === index && (
        <Box p={7} height="100%">
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const BusinessManagerPage = props => {
  const adContext = useContext(AdvertiserContext);
  const orgContext = useOrg();
  const [tabIndex, setTabIndex] = useState('Organization');
  const [forceRerenderedTab, setForceRerenderedTab] = useState(null);

  const getTabIndex = useCallback(
    index =>
      forceRerenderedTab?.index === index ? uniqueId(`${index}_`) : index,
    [forceRerenderedTab]
  );

  const tabs = orgContext?.org ? [
    {
      label: 'Organization',
      key: getTabIndex('Organization'),
      component: ManageOrganization,
      props: {},
    },
    {
      label: 'Ad Accounts',
      key: getTabIndex('Ad Accounts'),
      component: AdAccounts,
      props: {
        org: orgContext.org,
      },
    },
    {
      label: 'Billing Accounts',
      key: getTabIndex('Billing Accounts'),
      component: BillingAccounts,
      props: {
        org: orgContext.org,
      },
    },
    {
      label: 'Users',
      key: getTabIndex('Users'),
      component: UserAccounts,
      props: {
        org: orgContext.org,
      },
    },
  ] : [];

  const handleTabChange = (event, newValue) => {
    if (newValue === tabIndex) {
      setForceRerenderedTab({ index: newValue });
    } else {
      setForceRerenderedTab(null);
      setTabIndex(newValue);
    }
  };

  return (
    <StyledAppHeader history={props.history}>
      <Paper
        elevation={0}
        style={{
          margin: '32px 24px',
          minHeight: 880,
        }}
        variant="outlined"
      >
        {!adContext.id ? (
          <LoadingSpinner />
        ) : (
          <Box display="flex" width="100%" height="100%" position="relative">
            <Box className={classes.headerWrap}>
              <Typography className={classes.header}>
                BUSINESS MANAGER
              </Typography>

              <Typography className={classes.company}>
                {adContext.name}
              </Typography>
            </Box>

            <Tabs
              orientation="vertical"
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="Business Manager Tabs"
              className={classes.tabs}
            >
              {tabs.map(({ key, label }) => (
                <Tab
                  key={key}
                  className={classes.tab}
                  label={label}
                  value={label}
                />
              ))}
            </Tabs>

            {tabs.map(({ key, label, component: Component, props }) => (
              <TabPanel key={key} value={tabIndex} index={label}>
                <Component {...props} />
              </TabPanel>
            ))}
          </Box>
        )}
      </Paper>
    </StyledAppHeader>
  );
};

BusinessManagerPage.propTypes = {
  history: PropTypes.object,
};

export default BusinessManagerPage;
