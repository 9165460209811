import { useCallback } from 'react';
import { useRBACContext } from '../../providers/RBACContext';

/*
* @todo replace with useRBACContext
* @deprecated use useRBACContext instead
*
* @example
* const { permissions } = useRBACContext();
* ...
* {permissions.CAN_VIEW_NBCU_ORGANIZATION && <Component />}
* */
export const usePermissions = () => {
  const { permissions } = useRBACContext();

  const hasPermission = useCallback(
    (scopes, defaultValue = false) => permissions ? scopes.some(scope => permissions[scope]) : defaultValue,
    [permissions],
  );


  return { hasPermission, arePermissionsLoaded: !!permissions };
};
