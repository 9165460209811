import { FormFields } from '../components/forms/AdminForm/constants';

export const AdminAPIFields = {
  AGENCY_ID: 'nbcu_salesforce_agency_id',
  BILLING_ID: 'nbcu_agency_billing_id',
  DAILY_SPEND: 'daily_spend_limit',
  INVOICE_APPROVED: 'is_invoice_approved',
};

export const AdminFieldMappings = {
  [FormFields.AGENCY_ID]: AdminAPIFields.AGENCY_ID,
  [FormFields.BILLING_ID]: AdminAPIFields.BILLING_ID,
  [FormFields.DAILY_SPEND]: AdminAPIFields.DAILY_SPEND,
  [FormFields.INVOICE_APPROVED]: AdminAPIFields.INVOICE_APPROVED,
};
