export const parseAddress = address => {
  const addressArray = address.split(', ');
  const { length } = addressArray;

  const zip = addressArray[length - 1];
  const state = addressArray[length - 2];
  const city = addressArray[length - 3];
  const streetAddress1 = addressArray[0];
  const streetAddress2 = addressArray[1] !== city ? addressArray[1] : '';

  return {
    city,
    state,
    postal_code: zip,
    street_address: streetAddress1,
    street_address_2: streetAddress2,
  }
};
