export const FormFields = {
  NAME: 'name',
  ADDRESS_1: 'address1',
  ADDRESS_2: 'address2',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',
  PHONE: 'phone',
  DBA: 'dba',
};

export const DefaultValues = {
  [FormFields.NAME]: '',
  [FormFields.ADDRESS_1]: '',
  [FormFields.ADDRESS_2]: '',
  [FormFields.CITY]: '',
  [FormFields.STATE]: '',
  [FormFields.ZIP]: '',
  [FormFields.PHONE]: '',
  [FormFields.DBA]: '',
};
