import React from 'react';
import { PlaceholderCell } from './PlaceholderCell';
import { TableLeftHeader } from './TableLeft';
import { TableLeft } from './TableLeft/TableLeft';
import { TableRightHeader } from './TableRight';
import { TableRight } from './TableRight/TableRight';
import { defaultTableSettings } from './constants';
import { useTable } from './hooks/useTable';
import {
  AdvancedListBoxStyled,
  AdvancedSelectedBoxStyled,
  AdvancedWrapperStyled,
} from './styles';
import { transformFromGroupedData, transformToIncludable } from './utils';
import { isNull } from 'lodash';
import { TableLeftVirtualized } from './TableLeft/TableLeftVirtualized';
import { PlaceholderGroupVirtualized } from './PlaceholderGroupVirtualized';
import { PlaceholderGroup } from './PlaceholderGroup';

import 'react-virtualized/styles.css';
import { TableRightVirtualized } from './TableRight/TableRightVirtualized';
import { tableType } from './types';

export const Table = ({
  data,
  onChange,
  dataName,
  selected,
  selectedFilter,
  onFilter,
  minRowsAmount,
  renderCell,
  settings = defaultTableSettings,
  renderGroup,
  transform,
  virtualized = false,
  onIncludeAll,
  onExcludeAll,
  ...props
}) => {
  const {
    filteredSelected,
    data: actualData,
    isAddAllHovered,
    setIsAddAllHovered,
    isRemoveAllHovered,
    setIsRemoveAllHovered,
  } = useTable({ data, minRowsAmount, selected, selectedFilter });

  const TableLeftComponent = virtualized ? TableLeftVirtualized : TableLeft;
  const TableRightComponent = virtualized
    ? TableRightVirtualized
    : TableRight;

  return (
    <AdvancedWrapperStyled direction="row" {...props}>
      <AdvancedListBoxStyled flex={1}>
        <TableLeftHeader
          data={data}
          onFilter={onFilter}
          onAddAllHovered={setIsAddAllHovered}
          onAddAll={() =>
            onChange(
              [
                ...filteredSelected,
                ...transformFromGroupedData(data, selected, settings),
              ].filter(v => !isNull(v)),
            )
          }
        />
        <TableLeftComponent
          data={actualData}
          dataName={dataName}
          renderCell={renderCell}
          renderGroup={renderGroup}
          PlaceholderCell={PlaceholderCell}
          PlaceholderGroup={
            virtualized ? PlaceholderGroupVirtualized : PlaceholderGroup
          }
          isTargeted={isAddAllHovered}
          onChange={v =>
            onChange([
              transformToIncludable(v, selected, settings),
              ...selected,
            ])
          }
        />
      </AdvancedListBoxStyled>
      <AdvancedSelectedBoxStyled flex={1}>
        <TableRightHeader
          settings={settings}
          data={selected}
          onRemoveAll={() => onChange([])}
          onIncludeAll={onIncludeAll}
          onExcludeAll={onExcludeAll}
          onRemoveAllHovered={setIsRemoveAllHovered}
        />
        <TableRightComponent
          settings={settings}
          data={
            (transform?.right
              ? transform.right(filteredSelected)
              : filteredSelected) ?? []
          }
          PlaceholderCell={PlaceholderCell}
          PlaceholderGroup={
            virtualized ? PlaceholderGroupVirtualized : PlaceholderGroup
          }
          renderCell={renderCell}
          renderGroup={renderGroup}
          isTargeted={isRemoveAllHovered}
          onUpdate={v => onChange(selected.map(s => (s.id === v.id ? v : s)))}
          onChange={v => onChange(selected.filter(s => s.id !== v.id))}
          isRemoveAllHovered={isRemoveAllHovered}
        />
      </AdvancedSelectedBoxStyled>
    </AdvancedWrapperStyled>
  );
};

Table.propTypes = {
  ...tableType,
};
