import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { useCurrentSession } from '../currentSession';

export const buildOrganizationCacheKey = id => ({ url: `/organizations/${id}/` });
export const buildNBCUOrganizationCacheKey = id => ({ url: `/organizations/${id}/nbcu/` });

export const useGetOrganization = (id, options) => {
  const { getV1, apiIsReady } = useCurrentSession();

  return useSWR(
    apiIsReady && id ? buildOrganizationCacheKey(id) : null,
    ({ url, params }) => getV1(url, { params }).then(res => res.data),
    options,
  );
};

export const useGetNBCUOrganization = (id, options) => {
  const { getV1, apiIsReady } = useCurrentSession();

  return useSWR(
    apiIsReady && id ? buildNBCUOrganizationCacheKey(id) : null,
    ({ url, params }) => getV1(url, { params }).then(res => res.data),
    options,
  );
};

export const usePatchOrganization = (id, options) => {
  const { patchV1, apiIsReady } = useCurrentSession();

  return useSWRMutation(
    apiIsReady && id ? buildOrganizationCacheKey(id) : null,
    ({ url }, { arg }) => patchV1(url, arg).then(res => res.data),
    options,
  );
};

export const usePutNBCUOrganization = (id, options) => {
  const { putV1, apiIsReady } = useCurrentSession();

  return useSWRMutation(
    apiIsReady && id ? buildNBCUOrganizationCacheKey(id) : null,
    ({ url }, { arg }) => putV1(url, arg).then(res => res.data),
    options,
  );
};
