import { TableBody, TableHead, TableRow } from '@mui/material';
import { PropTypes } from 'prop-types';
import React, { Fragment } from 'react';
import {
  AdvancedTableStyled,
  FirstCellStyled,
  LastCellStyled,
  TableContainerStyled,
} from '../styles';
import { optionalOptionType } from '../types';
import { TableLeftRow } from './TableLeftRow';

export const TableLeft = ({
  data = [],
  dataName,
  onChange,
  renderCell,
  renderGroup,
  PlaceholderGroup,
  isTargeted,
  PlaceholderCell,
}) => {
  return (
    <TableContainerStyled>
      <AdvancedTableStyled>
        <TableHead>
          <TableRow>
            <FirstCellStyled>{dataName}</FirstCellStyled>
            <LastCellStyled />
          </TableRow>
        </TableHead>
        <TableBody data-testid="advanced-left-table-body">
          {data?.[0]?.groupName
            ? data.map((v, i) => (
                <Fragment key={`${v.groupName}-${i}`}>
                  <TableRow>
                    {renderGroup ? (
                      renderGroup({
                        data: v,
                        type: 'left',
                      })
                    ) : (
                      <PlaceholderGroup type={'left'} data={v} />
                    )}
                  </TableRow>
                  {v.data.map((d, j) => (
                    <TableLeftRow
                      key={data.id ?? `placeholder-${v.groupName}-${i}-${j}`}
                      data={d}
                      index={j}
                      isTargeted={v && isTargeted}
                      onChange={onChange}
                      PlaceholderCell={PlaceholderCell}
                      renderCell={renderCell}
                    />
                  ))}
                </Fragment>
              ))
            : data.map((v, i) => (
                <TableLeftRow
                  key={data.id ?? `placeholder-${i}`}
                  data={v}
                  index={i}
                  isTargeted={v && isTargeted}
                  onChange={onChange}
                  PlaceholderCell={PlaceholderCell}
                  renderCell={renderCell}
                />
              ))}
        </TableBody>
      </AdvancedTableStyled>
    </TableContainerStyled>
  );
};

TableLeft.propTypes = {
  data: PropTypes.arrayOf(optionalOptionType),
  dataName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isTargeted: PropTypes.bool,
  renderCell: PropTypes.func.isRequired,
  renderGroup: PropTypes.func,
  PlaceholderCell: PropTypes.func,
  PlaceholderGroup: PropTypes.func,
};
