import React from 'react';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { TableGroupCellStyledVirtualized } from './styles';

export const PlaceholderGroupVirtualized = ({ data, ...props }) => {
  return (
    <TableGroupCellStyledVirtualized {...props}>
      <Typography variant="caption" color="grey.1">
        {data.groupName}
      </Typography>
    </TableGroupCellStyledVirtualized>
  );
};

PlaceholderGroupVirtualized.propTypes = {
  ...Stack.propTypes,
  data: PropTypes.object,
  type: PropTypes.string,
};
