export const useTableVirtualizedData = ({ data }) => {
  return {
    list: data
      .map((v, i) =>
        v?.groupName
          ? [
              { id: i + 1, groupName: v.groupName, type: 'table-group-row' },
              ...v.data.map(d => ({ ...d, groupName: v.groupName })),
            ]
          : v,
      )
      .flat()
      .filter(Boolean),
  };
};
