const USER_GETS_VERTICAL_CAMPAIGN = 'USER_GETS_VERTICAL_CAMPAIGN';
const USER_GETS_VERTICAL_CAMPAIGN_DRAGS = 'USER_GETS_VERTICAL_CAMPAIGN_DRAGS';
const USER_GETS_NBCU_BIZMAN_UI = 'USER_GETS_NBCU_BIZMAN_UI';

export const Flags = {
  USER_GETS_VERTICAL_CAMPAIGN,
  USER_GETS_VERTICAL_CAMPAIGN_DRAGS,
  USER_GETS_NBCU_BIZMAN_UI,
};

export const DefaultFlags = {
  [USER_GETS_VERTICAL_CAMPAIGN]: false,
  [USER_GETS_VERTICAL_CAMPAIGN_DRAGS]: false,
  [USER_GETS_NBCU_BIZMAN_UI]: false,
};

