import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material';
import { fromHighlightedContent } from '@v2/components/campaign/Advanced/utils';
import { entries, map, reduce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { transformToContext, transformToGroups } from '../utils/transform';
import { getArrayValidation } from './validation';
import { useDebounce } from 'use-debounce';

const normalizeField = (data, field) =>
  map(data, i => ({
    ...i,
    [field]: fromHighlightedContent(i?.[field]),
  }));

export const useAdvancedGeneralTable = ({
  data = [],
  byTab = false,
  tab,
  defaultValues,
  selected: selectedData = [],
  selectedFilter,
  dataFieldName,
  groupByFieldName,
  resolver = () => yupResolver(getArrayValidation()),
  featuredFieldName = 'Featured',
  privateFieldName = 'Private',
  nonTypedFieldName = 'Non-Typed',
}) => {
  const form = useForm({
    criteriaMode: 'all',
    mode: 'all',
    resolver: resolver(),
    values: {
      items: !defaultValues ? selectedData : defaultValues.items,
    },
  });

  const { watch, setValue } = form;
  const selected = watch('items');

  const actualSelected = byTab
    ? selected[tab]
    : selectedFilter
      ? selectedFilter(selected)
      : selected;

  const [filter, setFilter] = useState('');
  const [debouncedFilter] = useDebounce(filter, 200);

  const passedData = useMemo(() => {
    const selectedIds = actualSelected.map(({ id }) => id);

    const transformed = transformToContext(
      data.filter(({ id }) => !selectedIds.includes(id)),
      debouncedFilter,
      dataFieldName,
    );

    if (groupByFieldName) {
      return transformToGroups({
        data: transformed,
        generalGroupField: groupByFieldName,
        featuredFieldName,
        privateFieldName,
        nonTypedFieldName,
      });
    }

    return transformed;
  }, [
    data,
    actualSelected,
    dataFieldName,
    debouncedFilter,
    groupByFieldName,
    featuredFieldName,
  ]);

  const handleSelect = useCallback(
    items => {
      setValue(
        'items',
        byTab
          ? reduce(
              entries(items),
              (acc, [key, value]) => ({
                ...acc,
                [key]: normalizeField(value, dataFieldName),
              }),
              {},
            )
          : normalizeField(items, dataFieldName),
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
    },
    [setValue],
  );

  return {
    theme: useTheme(),
    selected: actualSelected,
    data: passedData,
    filter: debouncedFilter,
    form,
    setSelected: handleSelect,
    setFilter,
  };
};
