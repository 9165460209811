import React from 'react';
import { TableRightRowVirtualized } from '../TableRightRowVirtualized';

export const useVirtualizedRenderer =
  ({
    isTargeted,
    renderCell,
    renderGroup,
    PlaceholderCell,
    PlaceholderGroup,
    isRemoveAllHovered,
    onChange,
    settings,
    onUpdate,
  }) =>
  /* eslint-disable react/prop-types */
  /* eslint-disable react/display-name */
  ({ key, index, style, rowData }) => {
    return rowData.type === 'table-group-row' ? (
      renderGroup ? (
        renderGroup({
          key,
          data: rowData,
          style,
          type: 'right',
        })
      ) : (
        <PlaceholderGroup
          style={style}
          key={key}
          type={'right'}
          data={rowData}
        />
      )
    ) : (
      <TableRightRowVirtualized
        key={key}
        data={rowData}
        index={index}
        isRemoveAllHovered={isRemoveAllHovered}
        onChange={onChange}
        onUpdate={onUpdate}
        renderCell={renderCell}
        isTargeted={isTargeted}
        PlaceholderCell={PlaceholderCell}
        settings={settings}
      />
    );
  };
