import { Stack, TableCell } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import {
  FirstCellVirtualizedStyled,
  LastCellVirtualizedStyled,
} from '../styles';
import { optionalOptionType, tableSettings } from '../types';
import { useTableVirtualizedData } from '../hooks/useTableVirtualizedData';
import { useVirtualizedRenderer } from './hooks/useVirtualizedRenderer';
import { AutoSizer, Table } from 'react-virtualized';

export const TableRightVirtualized = ({
  data = [],
  onChange,
  onUpdate,
  renderCell,
  renderGroup,
  isTargeted,
  PlaceholderCell,
  PlaceholderGroup,
  settings,
  isRemoveAllHovered,
}) => {
  const { list } = useTableVirtualizedData({ data });

  const rowRenderer = useVirtualizedRenderer({
    onChange,
    renderCell,
    onUpdate,
    renderGroup,
    PlaceholderCell,
    PlaceholderGroup,
    isRemoveAllHovered,
    isTargeted,
    settings,
  });

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          width={width}
          height={height}
          headerHeight={74}
          rowHeight={index => {
            const item = list[index];
            if (!item || item.type !== 'table-group-row') return 74;

            return 31;
          }}
          headerRowRenderer={() => (
            <Stack direction={'row'} spacing={0}>
              <FirstCellVirtualizedStyled>
                Selected
              </FirstCellVirtualizedStyled>
              {settings.includable && <TableCell>Incl/Excl</TableCell>}
              {settings.includable && settings.showSeparateSwitch && (
                <LastCellVirtualizedStyled />
              )}
            </Stack>
          )}
          rowCount={list.length}
          rowGetter={({ index }) => list[index]}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
};

TableRightVirtualized.propTypes = {
  data: PropTypes.arrayOf(optionalOptionType),
  onChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isTargeted: PropTypes.bool,
  renderCell: PropTypes.func.isRequired,
  renderGroup: PropTypes.func,
  PlaceholderCell: PropTypes.func,
  PlaceholderGroup: PropTypes.func,
  settings: tableSettings,
  isRemoveAllHovered: PropTypes.bool.isRequired,
};
