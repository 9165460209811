import {
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { Icon } from '../Icon';
import { defaultTo, get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AdditionalOptionsTypographyStyled = styled(Typography)(
  ({ theme }) => ({
    display: 'inline-flex',
    flexDirection: 'row',
    gap: theme.spacing(1.25),
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 700,
  }),
);

export const AvatarImageStyled = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'fill',
});

export const AdditionalOptionsIconStyled = styled(Icon)(({ theme }) => ({
  color: theme.palette.blue[12],
}));

export const DividerStyled = styled(Divider)(({ theme }) => ({
  '&.MuiDivider-root': {
    '&:before': {
      width: theme.spacing(2.25),
    },
  },
}));

export const DividerTypographyStyled = styled(Typography)({
  fontWeight: 700,
});

export const SectionBoxStyled = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(5),
}));

export const SectionInnerBoxStyled = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 2.25, 0),
  gap: theme.spacing(4),
}));

export const CancelButtonStyled = styled(Button)(({ theme }) => ({
  color: theme.palette.blue[6],
  flex: 1,
}));

export const TableCellAdditionalDataBlockStyled = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.grey[3],
    fontSize: theme.spacing(1.25),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  }),
);

export const LinkStyled = styled(Link)(() => ({
  fontWeight: 700,
  fontSize: '0.875rem',
  gap: 4,
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const CellContentStyled = styled(Stack, {
  shouldForwardProp: prop => prop !== 'color' && prop !== 'featured',
})(({ theme, color, featured }) => ({
  '&::before': {
    content: '""',
    width: 4,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: featured
      ? get(theme.palette, defaultTo(color, 'green.9'))
      : 'transparent',
  },
}));

export const CellContentVirtualizedStyled = styled(CellContentStyled)(
  ({ theme }) => ({
    padding: theme.spacing(2),
  }),
);

export const ReactiveIconStyled = styled(Icon)(({ theme }) => ({
  color: theme.palette.green[6],
  width: theme.spacing(2.25),
  height: theme.spacing(2.25),
}));

export const AdvancedSectionIconStyled = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    color: theme.palette.blue[6],
    cursor: 'pointer',
    width: theme.spacing(2.4375),
    height: theme.spacing(2.4375),
  }),
);
