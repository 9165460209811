import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from './Table';
import { Tabs } from './Tabs';
import { LegendTypographyStyled } from './styles';
import { dataType, tableType } from './types';
import { useAdvanced } from './hooks/useAdvanced';

const Advanced = ({
  data = [],
  tabs,
  selectedFilter,
  selectedTab,
  onChangeTab,
  legend = [],
  minRowsAmount = 0,
  onChange,
  transform,
  virtualized = false,
  ...props
}) => {
  const { tab } = useAdvanced({
    tabs,
    selectedTab,
  });

  return (
    <Stack flex={1} {...props}>
      <Stack direction="row" alignItems="flex-end">
        <Tabs
          flex={1}
          data={tabs}
          selected={selectedTab}
          onChange={onChangeTab}
        />
      </Stack>
      <Stack spacing={1.5} flex={1}>
        <Stack flex={1}>
          <Table
            {...props}
            selectedTab={selectedTab}
            dataName={tab.label}
            data={data}
            transform={transform}
            settings={tab.settings}
            minRowsAmount={minRowsAmount}
            selectedFilter={selectedFilter}
            virtualized={virtualized}
            onChange={onChange}
          />
        </Stack>
        {!!legend.length && (
          <Stack
            direction="row"
            spacing={3}
            data-testid="advanced-table-legend"
          >
            {legend.map(l => (
              <Stack
                key={l.label}
                direction="row"
                alignItems="center"
                spacing={1.25}
              >
                {l.type === 'node' ? (
                  l.value
                ) : (
                  <Stack
                    width={4}
                    height={12}
                    borderRadius={2}
                    bgcolor={l.value}
                  />
                )}
                <LegendTypographyStyled>{l.label}</LegendTypographyStyled>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

Advanced.propTypes = {
  ...tableType.propTypes,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      hint: PropTypes.string,
    }),
  ).isRequired,
  legend: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['color', 'node']),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      label: PropTypes.string.isRequired,
    }),
  ),
  data: dataType,
  byTab: PropTypes.bool,
  transform: PropTypes.func,
  selectedTab: PropTypes.string.isRequired,
  minRowsAmount: PropTypes.number,
  onChangeTab: PropTypes.func.isRequired,
  virtualized: PropTypes.bool,
};

export default Advanced;
